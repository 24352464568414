var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Table',{ref:"table",attrs:{"attr":{
    dense: true,
    'item-key': 'id',
  },"itemsPerPage":_vm.itemsPerPage,"itemsPerPageOptions":[30, 100, 200, 500],"headers":_vm.headers,"total":_vm.total,"multiSort":true,"filter":_vm.filter,"items":_vm.items,"funReset":_vm.funReset,"sortField":[]},scopedSlots:_vm._u([{key:"item.isCompany",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"text-overflow"},[(item.isCompany)?_c('span',[_vm._v("法人")]):_c('span',[_vm._v("個人")])])]}},{key:"item.lastClientActionHistory.actionAt",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.lastClientActionHistory ? item.lastClientActionHistory.actionAt : '')+" ")])]}},{key:"item.furtherPurchaseRate.name",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.furtherPurchaseRate ? item.furtherPurchaseRate.name : '')+" ")])]}},{key:"item.renewPurchaseRate.name",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.renewPurchaseRate ? item.renewPurchaseRate.name : '')+" ")])]}},{key:"item.purchaseRate.name",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.purchaseRate ? item.purchaseRate.name : '')+" ")])]}},{key:"item.campaignName",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.lastCampaignApplyResponse ? item.lastCampaignApplyResponse.campaign.name : '')+" ")])]}},{key:"item.lastClientActionHistoryRemarks",fn:function(ref){
  var item = ref.item;
return [(item.lastClientActionHistory)?_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.lastClientActionHistory.remarks)+" ")]):_vm._e()]}},{key:"item.remarksForStaff",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.remarksForStaff ? item.remarksForStaff : '')+" ")])]}},{key:"item.pic.name",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.pic ? item.pic.name : '')+" ")])]}},{key:"item.CustomerDetails",fn:function(ref){
  var item = ref.item;
return [_c('v-btn',{staticClass:"t-btn--prm",attrs:{"icon":""},on:{"click":function($event){return _vm.$openRoute.client(item.id)}}},[_c('v-icon',[_vm._v(" mdi-human-handsup")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }