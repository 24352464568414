<template>
  <mypage>
    <template v-slot:default>
      <template>
        <div class="d-flex justify-start mb-2">
          <v-tabs class="response-tab" v-model="tab" align-with-title>
            <v-tabs-slider color="#13ACE0"></v-tabs-slider>
            <v-tab exact v-for="(item, i) in items" :key="i" :to="item.to"
              >{{ item.title }}
            </v-tab>
          </v-tabs>
        </div>
      </template>
      <div class="mx-10 my-2">
        <slot name="content" />
      </div>
    </template>
  </mypage>
</template>
<script>
import Mypage from '../Mypage.vue';
export default {
  components: { Mypage },
  name: 'CustomerInChargeListTab',
  data() {
    return {
      // Tabs
      tab: null,
      items: [
        {
          title: '購入評価',
          to: '/personal-report/customer-in-charge',
        },
        {
          title: '買増評価',
          to: '/personal-report/customer-in-charge/additional-purchase',
        },
        {
          title: '更新評価',
          to: '/personal-report/customer-in-charge/update-evaluation',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
