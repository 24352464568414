<template>
  <div>
    <div>
      <div class="header-main d-flex justify-space-between color-title">
        <h1 class="my-4 page-title-main">{{name}} マイページ</h1>
      </div>
      <template>
        <v-tabs class="mb-2" v-model="tab" align-with-title>
          <v-tabs-slider color="#13ace0"></v-tabs-slider>
          <v-tab
            class="page-title-tab"
            v-for="(item, i) in items"
            :key="i"
            :to="item.to"
            @click="curentTab = i"
            >{{ item.title }}
          </v-tab>
        </v-tabs>
        <slot name="default" />
      </template>
    </div>
  </div>
</template>
<script>
import { checkPermissionTab } from '@/utils/permissions'

export default {
  name: 'Mypage',
  data() {
    return {
      name: localStorage.getItem("name"),
      tab: null,
      items: [],
      itemsTmp: [
        {
          title: 'マイページ',
          to: '/personal-report/my-page',
          pathRoot: '/personal-report/my-page',
          order: 1
        },
        {
          title: '担当顧客',
          to: '/personal-report/customer-in-charge',
          pathRoot: '/personal-report/customer-in-charge',
          order: 2
        },
        {
          title: '体験宿泊顧客',
          to: '/personal-report/experience',
          pathRoot: '/personal-report/experience',
          order: 3
        },
        {
          title: '対面顧客',
          to: '/personal-report/meeting',
          pathRoot: '/personal-report/meeting',
          order: 4
        },
      ],
    };
  },
  mounted () {
    this.checkPerTab()
  },
  methods: {
    checkPermissionTab,
    checkPerTab () {
      let itemCheck = this.checkPermissionTab(this.itemsTmp)
      itemCheck = [...new Set(itemCheck)]
      itemCheck.sort((a, b) => a.order - b.order)
      this.items = itemCheck
    }
  },
  components: {},
};
</script>

<style lang="scss" scope>
.v-tab:not(.v-tab--active) {
  background-color: #ffffff;
  color: #aaaaaa !important;
  &:hover {
    background-color: #eeeeee;
    color: #13ace0 !important;
  }
}
.v-tab--active {
  background-color: #ffffff !important;
  color: #13ace0 !important;
}

.v-tab--active:hover {
  background-color: #ceeffb !important;
  color: #13ace0 !important;
}
.color-title {
  background-color: #f8f8f8 !important;
}
</style>
