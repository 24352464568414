<template>
  <v-select
      :items="campaignsWithNonSelected"
      item-text="name"
      item-value="id"
      :value="value"
      @input="$listeners.input"
      dense
      outlined
      hide-details
      class="selectLast ml-2 mr-4 v-select-custom"
      style="width: 150px !important;"
  />
</template>

<script>
import gql from 'graphql-tag'
export default {
  props: {
    value: {type: Number, default: null}
  },
  data() {
    return {
      campaigns: [],
    }
  },
  computed: {
    campaignsWithNonSelected() {
      return [{id: null, name: '指定なし'}].concat(this.campaigns)
    }
  },
  apollo: {
    campaigns: {
      query: gql`
        query {
          campaignList (filter: {lastApplied: true}) {
            items {
              id name
            }
          }
        }
      `,
      update: (data) => data.campaignList.items,
    },
  }
}
</script>