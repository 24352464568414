<template>
  <Table
    :attr="{
      dense: true,
      'item-key': 'id',
    }"
    ref="table"
    :itemsPerPage="itemsPerPage"
    :itemsPerPageOptions="[30, 100, 200, 500]"
    :headers="headers"
    :total="total"
    :multiSort="true"
    :filter="filter"
    :items="items"
    :funReset="funReset"
    :sortField="[]"
  >
    <template v-slot:[`item.isCompany`]="{ item }">
      <div class="text-overflow">
        <span v-if="item.isCompany">法人</span>
        <span v-else>個人</span>
      </div>
    </template>
    <template v-slot:[`item.lastClientActionHistory.actionAt`]="{ item }">
      <div class="text-truncate">
        {{
          item.lastClientActionHistory ? item.lastClientActionHistory.actionAt : ''
        }}
      </div>
    </template>
    <template v-slot:[`item.furtherPurchaseRate.name`]="{ item }">
      <div class="text-truncate">
        {{
          item.furtherPurchaseRate ? item.furtherPurchaseRate.name : ''
        }}
      </div>
    </template>
    <template v-slot:[`item.renewPurchaseRate.name`]="{ item }">
      <div class="text-truncate">
        {{
          item.renewPurchaseRate ? item.renewPurchaseRate.name : ''
        }}
      </div>
    </template>
    <template v-slot:[`item.purchaseRate.name`]="{ item }">
      <div class="text-truncate">
        {{
          item.purchaseRate ? item.purchaseRate.name : ''
        }}
      </div>
    </template>
    <template v-slot:[`item.campaignName`]="{ item }">
      <div class="text-truncate">
        {{
          item.lastCampaignApplyResponse
            ? item.lastCampaignApplyResponse.campaign.name
            : ''
        }}
      </div>
    </template>
    <template v-slot:[`item.lastClientActionHistoryRemarks`]="{ item }">
      <div v-if="item.lastClientActionHistory" class="text-truncate">
        {{
          item.lastClientActionHistory.remarks
        }}
      </div>
    </template>
    <template v-slot:[`item.remarksForStaff`]="{ item }">
      <div class="text-truncate">
        {{ item.remarksForStaff ? item.remarksForStaff : '' }}
      </div>
    </template>
    <template v-slot:[`item.pic.name`]="{ item }">
      <div class="text-truncate">
        {{ item.pic ? item.pic.name : '' }}
      </div>
    </template>
    <template v-slot:[`item.CustomerDetails`]="{ item }">
      <v-btn
        icon
        class="t-btn--prm"
        @click="$openRoute.client(item.id)"
      >
        <v-icon> mdi-human-handsup</v-icon>
      </v-btn>
    </template>
  </Table>
</template>

<script>
import Table from '@/components/Table/index.vue';

export default {
  components: {Table},
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    filter: {
      default: () => {},
      type: Object,
    },
    total: Number,
    funReset: {
      type: Function,
      default: null,
    },
    additional: Boolean,
    update: Boolean,
    purchase: Boolean
  },
  data () {
    return {
      itemsPerPage: 30,
    }
  },
  computed: {
    leftHeaders () {
      return [
        {
          text: '顧客詳細',
          value: 'CustomerDetails',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('myPage.CustomerInCharge.Echoday'),
          value: 'lastResponseDate',
          fieldName: 'client.lastResponseDate',
          sortable: true,
        },
        {
          text: this.$t('myPage.CustomerInCharge.LastActivityDate'),
          value: 'lastClientActionHistory.actionAt',
          fieldName: 'action.actionAt',
          sortable: true,
        },
      ]
    },
    rightHeaders () {
      return [
        {
          text: '個人/法人',
          value: 'isCompany',
          fieldName: 'client.isCompany',
          sortable: true,
        },
        {
          text: '顧客名',
          value: 'name',
          sortable: false,
        },
        {
          text: '顧客名フリガナ',
          value: 'nameKana',
          sortable: false,
        },
        {
          text: '最新広告施策',
          value: 'campaignName',
          fieldName: 'lastCampaign.name',
          sortable: true,
        },
        {
          text: '最新活動状況',
          value: 'lastClientActionHistoryRemarks',
          fieldName: 'action.remarks',
          sortable: true,
        },
        {
          text: '他のスタッフへの要共有事項',
          value: 'remarksForStaff',
          fieldName: 'client.remarksForStaff',
          sortable: true,
        },
        {
          text: '営業担当',
          value: 'pic.name',
          sortable: true,
        },
        {
          text: '会員番号',
          value: 'id',
          fieldName: 'client.id',
          sortable: true,
        },
      ]
    },
    centerHeaders () {
      const headers = [];
      if (this.additional) {
        headers.push({
          text: '買増評価',
          value: 'furtherPurchaseRate.name',
          fieldName: 'client.furtherPurchaseRateId',
          sortable: true,
        })
      }
      if (this.update) {
        headers.push({
          text: '更新評価',
          value: 'renewPurchaseRate.name',
          fieldName: 'client.renewPurchaseRateId',
          sortable: true,
        })
      }
      if (this.purchase) {
        headers.push({
          text: '購入評価',
          value: 'purchaseRate.name',
          fieldName: 'client.purchaseRateId',
          sortable: true,
        })
      }
      return headers
    },
    headers () {
      return this.leftHeaders
        .concat(this.centerHeaders)
        .concat(this.rightHeaders)
    }
  },
  methods: {
    reset () {
      this.$refs.table.reset()
    },
    resetPageAndSearch () {
      this.$refs.table.resetPageAndSearch()
    },
  }
}
</script>